<template>
  <section class="privacy">
    <div class="title">
      Privacy Policy
    </div>
    <div class="privacy-name">
      Helen Bohleber
      <div class="privacy-address">
          <div>Mainzer Str. 22</div>
          <div>56068 Koblenz</div>
          <div><i>E-Mail</i> hochzeit@helenundemil.de</div>
          <div><i>Telefon</i> 01607942339</div>
      </div>
    </div>
    <div class="privacy-text">
        Camera Permission is needed to scan QR Codes, which are generated by the app itself.<br/>
        QR Codes include information which relationship he/she has to the bridal couple,
        on which table the person is sitting and whether the person speaks german or polish.<br/>
        This data is setup on app start on a page where the user can set it via
        dropdowns and buttons.
        This data is saved in shared preferences and can be deleted by the user.<br/>
        Before deleting it the user can see his data in his settings.
        He/she can also export the data from shared preferences to see it.<br/>
        This data is used to scan QR Codes from other guests to determine whether
        a challenge like 'take a picture with somebody' is accepted.<br/>
        No third parties have access to this information.<br/><br/>
        The camera permission is not mandatory. If the user declines,
        he can use the app to provide his own QR Code and see the menu of the wedding.<br/>
        07.08.2021
    </div>
  </section>
</template>

<style scoped>

.privacy{
  margin-top: 70px;
  padding-bottom: 40px;
  font-family: 'Crimson Pro', serif;
  color: var(--hochzeit-text-green);
}

.privacy .title{
    font-size: 50px;
    text-shadow: 2px 2px 4px #0000000d;
    margin-bottom: 40px;
}

.privacy-name{
  margin-top: 60px;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 30px;
}

.privacy-address{
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 24px;
  color: var(--hochzeit-text-green);
}

.privacy-text{
  margin-top: 60px;
  font-size: 24px;
}

.privacy-link{
    color: var(--hochzeit-text-pink);
}
</style>
