<template>
  <Header />
  <Timer />
  <News />
  <WeSection />
  <PartyLocation />
  <Imprint />
</template>

<script>
import Header from './Header.vue';
import Timer from './Timer.vue';
import News from './News/News.vue';
import PartyLocation from './PartyLocation/PartyLocation.vue';
import WeSection from './we/WeSection.vue';
import Imprint from './Imprint.vue';

export default {
  name: 'Wedding',
  components: {
    Header,
    Timer,
    News,
    PartyLocation,
    WeSection,
    Imprint,
  },
};
</script>

<style scoped>
.hochzeit-page{
  font-family: 'Bodoni Moda', serif;
  background: #FFFAFA;
  color: var(--hochzeit-text-green);
  width: auto;
  max-width: 1280px;
  text-shadow:  #00000028;

}

.hochzeit-page span{
  background-color: var(--hochzeit-text-green);
  color: transparent;
  text-shadow: 3px 3px 2px rgba(255,255,255,0.2);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}

.hochzeit-page .hochzeit-header{
  font-family: "Mystical Snow";
  color: var(--hochzeit-text-pink);
  font-size: 120px;
  text-shadow: 3px 3px 2px #0000000d;
}
</style>
