<template>
  <section class="party-location">
    <div class="party-location-name">
      <i>{{$t('partyLocation.titleItalic')}}</i>
      {{$t('partyLocation.titleNormal')}}
    </div>
    <div class="party-loaction-elements" />
    <div class="party-location-name">
      <i>{{$t('partyLocation.in')}}</i> Gut Nettehammer
      <div class="party-location-address">
          <div>Gut Nettehammer 1</div>
          <div>56626 Andernach</div>
      </div>
    </div>
    <div class="party-location-infos">
      <img class="image" src="https://cdn6.site-media.eu/images/1920/2670831/heiraten-in-koblenz.png" />
      <img class="image" src="https://cdn6.site-media.eu/images/1920/2670696/hochzeitslocation-nahe-koeln.png" />
    </div>
  </section>
</template>

<style scoped>

.party-location{
  margin-top: 70px;
}

.party-loaction-elements{
  position: relative;
  background: url('https://cdn6.site-media.eu/images/1920/2667213/hochzeitslocation-nahe-bonn.jpg') no-repeat;
  height: 60vw;
  background-size: cover;
  margin-top: 60px;
  box-shadow: 2px 2px 3px #0000003d inset;
  width:100%;
}

.party-loaction-elements .lead-image{
  width: 70%;
  left: 0;
}

.party-location .title{
    font-family: "Mystical Snow";
    font-size: 50px;
    color: var(--hochzeit-text-pink);
    text-shadow: 2px 2px 4px #0000000d;
    margin-bottom: 40px;
}

.party-location-name{
  margin-top: 60px;
  font-family: 'Crimson Pro', serif;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 50px;
  color: var(--hochzeit-text-green);
}

.party-location-address{
  font-family: 'Crimson Pro', serif;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 30px;
  color: var(--hochzeit-text-green);
}

.party-location-map {
  font-family: 'Crimson Pro', serif;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 30px;
  color: var(--hochzeit-text-green);
  margin-top: 60px;
}

.party-location-infos{
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.party-location-infos .image{
  width: 50%;
}

.party-location-map .image {
  margin-top: 40px;
  width: 50%;
  box-shadow: 2px 2px 3px #0000003d;
}

@media screen and (max-width:1000px){
  .party-location-map .image{
    width: 90%;
  }
}

@media screen and (max-width:600px){
  .party-location-map .image{
    width: 90%;
  }
}

@media screen and (max-width:400px){
  .party-location-map .image{
    width: 90%;
  }
}

</style>
