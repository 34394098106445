<template>
  <section class="news">
    <ContentContainer>
      <div class="news-info">
        <div class="news-block center">
          <h1>{{$t('news.married')}}</h1>
          <a href="https://drive.google.com/drive/folders/11U1rntLIKfUQpmy1nPt7LYou5W8rtgOo?usp=sharing" class="news-link">{{$t('news.photo')}}</a>
        </div>
        <div class="news-block center">
          <h1>{{$t('news.winnerTitle')}}</h1>
          <p>{{$t('news.winnerText')}}</p>
          <img class="news-img" src="@/assets/images/winner.jpg" />
        </div>
      </div>
    </ContentContainer>
  </section>
</template>

<script>
import ContentContainer from '../ContentContainer/ContentContainer.vue';

export default {
  components: {
    ContentContainer,
  },
};
</script>

<style scoped>
.news {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
}

.news .news-title {
  font-family: "Crimson Pro", serif;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 50px;
  color: var(--hochzeit-text-green);
}

.news .news-info {
  width: 100%;
  margin-top: 50px;
  padding: 0 30px;
}

.news .news-info .news-block {
  margin-top: 20px;
  text-align: left;
  font-family: "Crimson Pro", serif;
  letter-spacing: 3px;
  font-size: 25px;
  color: var(--hochzeit-text-green);
}

.news .news-info .news-block.center{
  text-align: center;
}

.news .news-info .news-block .news-link {
  font-family: "Crimson Pro", serif;
  letter-spacing: 3px;
  font-size: 25px;
  color: var(--pink);
}

.news .news-img {
  width: 50%;
}
</style>
