import { createI18n } from 'vue-i18n';
import de from '@/locales/de';
import pl from '@/locales/pl';

export const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de, pl,
  },
});
