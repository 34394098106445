<template>
  <Colors />
  <Fonts />
  <router-view/>
</template>

<script>
import AWS from 'aws-sdk';

import { onMounted } from 'vue';
import Colors from './theme/colors.vue';
import Fonts from './theme/fonts.vue';
import { getBrowserLocales } from './plugins/getBrowserLocales';
import { i18n } from './plugins/lang';

export default {
  components: {
    Colors,
    Fonts,
  },
  setup() {
    AWS.config.update({
      region: 'us-east-2',
      endpoint: 'dynamodb.us-east-2.amazonaws.com',
      accessKeyId: 'AKIA4L6IX5UGLWHTOSXV',
      secretAccessKey: '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',
    });

    onMounted(() => {
      const [locale] = getBrowserLocales({ languageCodeOnly: true });
      i18n.global.locale = locale;
    });
  },
};
</script>

<style>

@font-face {
  font-family: 'Dancing Script';
  src: url('./assets/DancingScript-Bold.ttf');
}
@font-face {
  font-family: 'Anonymous Pro';
  src: url('./assets/AnonymousPro-Regular.ttf');
}

#app {
  font-family: sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

</style>
