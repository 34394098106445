import { createRouter, createWebHashHistory } from 'vue-router';
import WeddingPage from '@/wedding/WeddingPage.vue';
import PrivacyPage from '@/privacypolicy/PrivacyPage.vue';

const routes = [
  {
    path: '/',
    name: 'Wedding',
    component: WeddingPage,
  },
  {
    path: '/kisses',
    name: 'Kiss Map',
    component: () => import(/* webpackChunkName: "map" */ '@/kissmap/Map.vue'),
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
