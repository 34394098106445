<template>
  <section class="home" v-bind:style="{backgroundPositionY: bgPosY + '%'}" >
    <ContentContainer height="45vw">
      <div class="title">Helen & Emil</div>
      <div class="subtitle">{{$t('header.subtitleLineOne')}} <br/>
        <span>{{$t('header.subtitleLineTwo.date')}}</span>
          {{$t('header.subtitleLineTwo.at')}}
          <span>{{$t('header.subtitleLineTwo.clock')}}</span>
      </div>
    </ContentContainer>
  </section>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import ContentContainer from './ContentContainer/ContentContainer.vue';

export default {
  components: {
    ContentContainer,
  },
  setup() {
    const bgPosY = ref(80);

    const scrolling = (evt) => {
      bgPosY.value = 80 - (evt.target.scrollTop / evt.target.scrollHeight) * 1000;
    };
    onMounted(() => {
      document.querySelector('body')
        .addEventListener('scroll', scrolling);
    });

    onUnmounted(() => {
      document.querySelector('body')
        .removeEventListener('scroll', scrolling);
    });

    return { bgPosY };
  },
};
</script>

<style scoped>
.home{
  position: relative;
  background: url('../assets/hochzeit03.jpg') no-repeat;
  background-position-x: 35%;
  background-size: cover;
  height: 45vw;
  box-shadow: 0 -3px 2px #0000000d inset;
}

.home .title{
  position: relative;
  font-family: "Mystical Snow";
  color: var(--hochzeit-text-pink);
  font-size: 10vw;
  text-shadow: 2px 2px 4px #0000003d;
  top: 10vw;
}
.home .subtitle{
  position: absolute;
  bottom: -40px;
  padding: 8px 15px;
  border-radius: 2px;
  color: var(--pink);
  font-weight: 900;
  font-size: 18px;
  font-family: 'Crimson Pro', serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  background: var(--background);
  box-shadow: 2px 2px 4px #0000003d;
}

@media screen and (max-width:600px){
  .home .subtitle {
    font-size: 14px;
    bottom: -36px;
    letter-spacing: 3px;
  }
}

@media screen and (max-width:400px){
  .home .subtitle {
    font-size: 12px;
    bottom: -30px;
    letter-spacing: 2px;
  }
}

.home .subtitle span{
  color: var(--hochzeit-text-green);
}
</style>
