export default {
  header: {
    subtitleLineOne: 'Haben ihre Hochzeit gefeiert am',
    subtitleLineTwo: {
      date: '14 August 2021',
      at: 'ab',
      clock: '14:00 Uhr',
    },
  },
  timer: {
    days: 'Tage',
    minutes: 'Minuten',
    hours: 'Stunden',
  },
  news: {
    title: 'Neuigkeiten',
    vaccinateCount: '44',
    vaccinateText: ' unserer 59 Gäste sind bis zur Hochzeit geimpft.',
    coronaTest: 'Alle Gäste, die weder geimpft noch genesen sind, benötigen zur Hochzeit einen Corona-Test (nicht älter als 12 Stunden). Am besten lasst ihr euch am Samstagvormittag testen. Link zu koblenzer Testzentren: ',
    coronaLink: 'Aktuelle Infos bzgl. Corona und Hochzeiten',
    nettehammer: 'Wie einige mitbekommen haben, hat die Flutkatastrophe auch das Gut Nettehammer erwischt. Es gibt jedoch keinen Grund zur Sorge, die Hochzeit kann wie geplant stattfinden. Es gab bereits schon eine Hochzeit nach der Flut dort. Lediglich der Anfahrtsweg kann anders sein, weil die Brücke über die Nette weggeschwemmt wurde.',
    doNotThrow: 'Wir bitten euch bei der Hochzeitsfeier draußen keine Dinge, wie Reis oder Konfetti zu werfen. Das hat der Besitzer ausdrücklich verboten.',
    taxi: 'Wir haben ein Taxiunternehmen informiert. Die Nummer ist 02632 49 11 11. Bitte nennt dazu, dass es sich um die Bohleber Hochzeit handelt. Leider muss selbst bezahlt werden. Wer sich aber eine Quittung geben lässt bekommt das Geld von uns wieder.',
    married: 'Wir sind verheiratet!',
    photo: 'Hier geht es zu den Fotos der Hochzeit',
    winnerTitle: 'Gewinner der App Aufgaben',
    winnerText: 'Robert hat alle Aufgaben am schnellsten erledigt.',
  },
  program: {
    program: 'Programm',
    start: '14:00 Trauung auf Gut Nettehammer',
    drink: '14:45 Sektempfang',
    cake: '15:30 Anschnitt der Hochzeitstorte',
    entrance: '18:30 Einlass in den Saal',
    dinner: '19:00 Abendessen',
  },
  we: {
    titleNormal: 'Das sind',
    titleItalic: 'Wir',
  },
  partyLocation: {
    titleItalic: 'Party',
    titleNormal: 'Location!',
    in: 'Im',
    directions: 'Anfahrt',
  },
  locationList: {
    title: 'Buch dir ein Zimmer',
    visitWebsite: 'Zur Webseite',
  },
  imprint: {
    title: 'Impressum',
    text: 'Design und Entwicklung von Emil Wasilewski und Helen Bohleber',
    weddingPhoto: 'Hochzeitsfotos von',
    photo: 'Fotos von',
  },
};
