export default {
  header: {
    subtitleLineOne: 'Świętowali swój ślub w dniu',
    subtitleLineTwo: {
      date: '14 sierpnia 2021',
      at: 'roku o godzinie',
      clock: '14:00',
    },
  },
  timer: {
    days: 'Dni',
    minutes: 'Minuty',
    hours: 'Godziny',
  },
  news: {
    title: 'Aktualności',
    vaccinateCount: '44',
    vaccinateText: ' z 59 naszych gości zostanie zaszczepionych przed ślubem.',
    coronaTest: 'Pamiętaj o zabraniu ze sobą karty szczepień.',
    coronaLink: 'Aktualne informacje dotyczące Korony i wesel w Niemczech (po niemiecku)',
    nettehammer: '',
    doNotThrow: 'Prosimy nie rzucać na wesele rzeczy takich jak ryż czy konfetti na zewnątrz. Właściciel wyraźnie tego zabronił.',
    taxi: 'Taksówka Nummer: +492632 49 11 11. W nazwie Bohleber.',
    married: 'Jesteśmy małżeństwem!',
    photo: 'Kliknij tutaj, aby obejrzeć zdjęcia z wesela',
    winnerTitle: 'Zwycięzcy konkursu App Tasks',
    winnerText: 'Robert wykonał wszystkie zadania najszybciej.',
  },
  program: {
    program: 'Program',
    start: '14:00 ślub na Gut Nettehammer',
    drink: '14:45 Szampan',
    cake: '15:30 Krojenie tortu weselnego',
    entrance: '18:30 Wstęp na halę',
    dinner: '19:00 Kolacja',
  },
  we: {
    titleNormal: 'To',
    titleItalic: 'My',
  },
  partyLocation: {
    titleItalic: 'Party',
    titleNormal: 'Location!',
    in: 'W',
    directions: 'Wskazówki',
  },
  locationList: {
    title: 'Zarezerwuj sobie pokój',
    visitWebsite: 'Do strony internetowej',
  },
  imprint: {
    title: 'Odcisk',
    text: 'Design und Entwicklung von Emil Wasilewski und Helen Bohleber',
    weddingPhoto: 'Hochzeitsfotos von',
    photo: 'Fotos von',
  },
};
