<template>
  <section class="timer">
    <ContentContainer>
      <div class="outer-border">
      <div class="timer-content">
        <div class="cd-group">
          <div class="number">{{days}}</div>
          <div class="unit" >{{$t('timer.days')}}</div>
        </div>
        <div class="dot" >&#8901;</div>
        <div class="cd-group">
          <div class="number">{{hours}}</div>
          <div class="unit" >{{$t('timer.hours')}}</div>
        </div>
        <div class="dot" >&#8901;</div>
        <div class="cd-group">
          <div class="number">{{minutes}}</div>
          <div class="unit" >{{$t('timer.minutes')}}</div>
        </div>
      </div>
      </div>
    </ContentContainer>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import ContentContainer from './ContentContainer/ContentContainer.vue';

const createReadableTimeText = (timeLeft) => {
  // eslint-disable-next-line no-bitwise
  const days = computed(() => ((timeLeft.value / 86400000) | 0));
  // eslint-disable-next-line no-bitwise
  const hours = computed(() => ((timeLeft.value - days.value * 86400000) / 3600000) | 0);
  const minutes = computed(
    // eslint-disable-next-line no-bitwise
    () => ((((timeLeft.value - days.value * 86400000) - hours.value * 3600000) / 60000) | 0) + 1,
  );
  return {
    days,
    hours,
    minutes,
    timeLeft,
  };
};

export default {
  components: {
    ContentContainer,
  },
  setup() {
    const timeLeft = ref(0);
    const date = new Date(2021, 7, 13, 15);
    const calculateTimeLeft = () => {
      timeLeft.value = new Date() - date;
      window.requestAnimationFrame(calculateTimeLeft);
    };

    onMounted(() => {
      calculateTimeLeft();
    });

    return {
      ...createReadableTimeText(timeLeft),
    };
  },
};
</script>

<style scoped>
.timer{
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  font-family: 'Bodoni Moda', serif;
}

.timer .timer-content{
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width: calc(100% - 24px);
  border: 2px dashed var(--light-green);
  padding: 10px;
  box-shadow: 0px 0px 4px #0000001d;
}

.timer .cd-group{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timer .cd-group .number{
  font-family: 'Crimson Pro', serif;
  font-size: 50px;
  letter-spacing: 5px;
  line-height: 50px;
  font-weight: 900;
  background-color: var(--dark-green);
  color: transparent;
  text-shadow: 3px 3px 2px rgba(255,255,255,0.2);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}

.timer .cd-group .unit{
  font-family: 'Crimson Pro', serif;
  margin-top: -4px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--hochzeit-text-green);
  color: transparent;
  text-shadow: 3px 3px 2px rgba(255,255,255,0.2);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}

.timer-content .dot{
  font-size: 40px;
  line-height: 35px;
  font-family: monospace;
  color: var(--dark-green);
}

.outer-border{
  width: 70%;
  min-width: 330px;
  border: 1px solid var(--light-green);
  padding: 10px;
  box-shadow: 0px 0px 4px #0000001d inset;
}

@media screen and (max-width:600px){
.timer{
  margin-top:50px;
}
.timer .cd-group .number{
  font-size: 40px;
  letter-spacing: 5px;
  line-height: 40px;
}
.timer .cd-group .unit{
  font-size: 12px;
}
.timer-content .dot{
  font-size: 40px;
  line-height: 35px;
}
}

@media screen and (max-width:400px){
.timer{
  margin-top:45px;
}
.timer .cd-group .number{
  font-size: 30px;
  letter-spacing: 3px;
  line-height: 30px;
}
.timer .cd-group .unit{
  font-size: 10px;
  margin-top: 0;
}
.timer-content .dot{
  font-size: 30px;
  line-height: 20px;
}
}

</style>
