<template>
  <section class="imprint">
    <div class="title">
      {{$t('imprint.title')}}
    </div>
    <div class="imprint-name">
      Emil Wasilewski und Helen Bohleber
      <div class="imprint-address">
          <div>Mainzer Str. 22</div>
          <div>56068 Koblenz</div>
          <div><i>E-Mail</i> hochzeit@helenundemil.de</div>
          <div><i>Telefon</i> 01607942339</div>
      </div>
    </div>
    <div class="imprint-text">
        {{$t('imprint.text')}} <br/>
        {{$t('imprint.weddingPhoto')}} <a href="https://kongret.uber.space/e" class="imprint-link">Greta Rettler</a> <br/>
        {{$t('imprint.photo')}} <a href="https://www.nettehammer.de/de" class="imprint-link">Gut Nettehammer</a>
    </div>
  </section>
</template>

<style scoped>

.imprint{
  margin-top: 70px;
  padding-bottom: 40px;
  font-family: 'Crimson Pro', serif;
  color: var(--hochzeit-text-green);
}

.imprint .title{
    font-size: 50px;
    text-shadow: 2px 2px 4px #0000000d;
    margin-bottom: 40px;
}

.imprint-name{
  margin-top: 60px;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 30px;
}

.imprint-address{
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 24px;
  color: var(--hochzeit-text-green);
}

.imprint-text{
  margin-top: 60px;
  font-size: 24px;
}

.imprint-link{
    color: var(--hochzeit-text-pink);
}
</style>
