<template>
  <div class="container" :style="{height: height}">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: { height: String },
};
</script>

<style scoped>
.container{
  width: calc(100% - 30px);
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.container .content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
