<template>
  <div />
</template>

<style>
html, body{
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  background: #FFFAFA;
  margin: 0;
  padding: 0;
}
:root{
  --blue: #6ACDE5;
  --pink: #E7B1CA;
  --green: #9ECCA8;
  --yellow: #EEECA4;
  --orange: #F7C6A4;

  --text-pink: #AD4F98;
  --text-orange: #E7AF85;

  --background: #FFFAFA;
  --hochzeit-text-pink: #EAD0D0;
  --hochzeit-text-green: #AAC4AC;
  --dark-green: #8BA08C;
  --light-green: #BED3BF;
  --super-light-green: #D0DDD0;
}
</style>
