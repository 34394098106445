<template>
  <section class="we-section">
    <ContentContainer>
      <div class="image-container">
        <div class="left-column">
          <div class="big-image" :style="bigH" />
        </div>
        <div class="right-column">
          <div class="big-image" :style="bigE" />
        </div>
      </div>
      <div class="title">{{$t('we.titleNormal')}}
        <i>{{$t('we.titleItalic')}}</i>!</div>
      <div class="wide-image" :style="bikeGif"/>
      <div class="galery-background">
        <div class="galery">
          <div
            class="galery-image"
            v-for="image in galeryImages"
            :key="image"
          >
          <img :src="image" />
          </div>
        </div>
      </div>
      <div class="wide-image" :style="danceGif"/>
    </ContentContainer>
  </section>
</template>

<script>
import ContentContainer from '../ContentContainer/ContentContainer.vue';
import { galeryImages } from './images';

export default {
  components: {
    ContentContainer,
  },
  setup() {
    const bigE = {
      backgroundImage: `url(${require('@/assets/images/photo_2021-02-11_23-10-28.jpg')})`,
    };
    const bigH = {
      backgroundImage: `url(${require('@/assets/images/photo_2021-02-11_23-10-30.jpg')})`,
    };
    const bikeGif = {
      backgroundImage: `url(${require('./images/bike.webp')})`,
    };
    const danceGif = {
      backgroundImage: `url(${require('./images/dance.webp')})`,
    };

    return {
      bigE,
      bigH,
      bikeGif,
      danceGif,
      galeryImages,
    };
  },
};
</script>

<style scoped>
.we-section{
  margin-top: 60px;
}

.we-section .title{
  margin-top: 60px;
  margin-bottom: 60px;
  font-family: 'Crimson Pro', serif;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 50px;
  color: var(--hochzeit-text-green);
}

.we-section .galery{
  max-width: 1440px;
  margin-top: 60px;
  margin-bottom: 60px;
  column-count: 5;
  column-gap: 1;

}

@media screen and (max-width:1000px){
  .we-section .galery{
    column-count: 4;
  }
}

@media screen and (max-width:600px){
  .we-section .galery{
    column-count: 3;
  }
}

@media screen and (max-width:400px){
  .we-section .galery{
    column-count: 2;
  }
}

.we-section .galery .galery-image{
  margin-bottom: 10px;
}

.we-section .galery .galery-image img{
  width: 100%;
  box-shadow: 2px 2px 3px #0000003d;
}

.we-section .image-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.we-section .image-container > div{
  margin-right: 30px;
  position: relative;
}

.image-container .right-column{
  margin-top: 30px;
}

.we-section .big-image{
  width: 35vw;
  height: 35vw;
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 2px 2px 3px #0000003d;
}

.we-section .wide-image{
  width: 1440px;
  height: 600px;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 2px 2px 3px #0000003d;
}

@media screen and (max-width:600px){
  .we-section .image-container > div{
    margin-right: 0;
  }
  .image-container .right-column{
    margin-top: 0;
  }
  .we-section .big-image{
    width: 50vw;
    height: 40vw;
  }

  .we-section .wide-image{
    width: 700px;
    height: 400px;
  }
}

.we-section .smaller-image{
  position: absolute;
  width: 300px;
  bottom:-100px;
  left:100px;
}
</style>
