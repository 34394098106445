<template>
  <PrivacyPolicy />
</template>

<script>
import PrivacyPolicy from './PrivacyPolicy.vue';

export default {
  name: 'PrivacyPolicyPage',
  components: {
    PrivacyPolicy,
  },
};
</script>

<style scoped>
.privacy-page{
  font-family: 'Bodoni Moda', serif;
  background: #FFFAFA;
  color: var(--hochzeit-text-green);
  width: auto;
  max-width: 1280px;
  text-shadow:  #00000028;

}

.privacy-page span{
  background-color: var(--hochzeit-text-green);
  color: transparent;
  text-shadow: 3px 3px 2px rgba(255,255,255,0.2);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}

.privacy-page .privacy-header{
  font-family: "Mystical Snow";
  color: var(--hochzeit-text-pink);
  font-size: 120px;
  text-shadow: 3px 3px 2px #0000000d;
}
</style>
