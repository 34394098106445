<template>
  <div />
</template>

<style>
@font-face {
  font-family: "Mystical Snow";
  src: url("./Mystical Snow.woff2") format("woff2");
}
</style>
