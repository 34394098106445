export const galeryImages = [
  `${require('./images/hochzeit02.jpg')}`,
  `${require('./images/photo_2021-02-11_23-10-32.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-44.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-42.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-35.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-29.jpg')}`,
  `${require('./images/hochzeit04.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-40.jpg')}`,
  `${require('./images/skate.webp')}`,
  `${require('./images/photo_2021-02-11_23-10-34.jpg')}`,
  `${require('./images/photo_2021-02-11_23-10-41.jpg')}`,
  `${require('./images/hochzeit01.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-34.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-38.jpg')}`,
  `${require('./images/photo_2021-02-11_23-10-36.jpg')}`,
  `${require('./images/photo_2021-02-11_23-10-46.jpg')}`,
  `${require('./images/hochzeit05.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-30.jpg')}`,
  `${require('./images/photo_2021-02-13_15-39-03.jpg')}`,
  `${require('./images/photo_2021-02-13_15-39-50.jpg')}`,
  `${require('./images/photo_2021-02-13_15-39-15.jpg')}`,
  `${require('./images/photo_2021-02-13_15-39-53.jpg')}`,
  `${require('./images/photo_2021-08-15_19-40-15.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-26.jpg')}`,
  `${require('./images/photo_2021-02-13_15-43-27.jpg')}`,
  `${require('./images/cam.webp')}`,
  `${require('./images/photo_2021-02-13_15-43-32.jpg')}`,
  `${require('./images/photo_2021-02-13_21-16-03.jpg')}`,
];
